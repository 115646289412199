import { authenticated, getPermisos } from "../utils/auth";
export const toOutsideApp = (to: any, from: any, next: any) => {
    if (to.location.pathname === "/login" || to.location.pathname === "/") {
        if (authenticated()) {
            next.redirect("/home");
        } else {
            next.redirect("/login");
        }
    } else {
        next()
    }
};
export const toInsideApp = (to: any, from: any, next: any) => {
    if (to.location.pathname.split("/")[1] === "home") {
        if (authenticated()) {
            next();
        } else {
            next.redirect("/login");
        }
    } else {
        next()
    }
};
export const toPages = (to: any, from: any, next: any) => {
    const arrayPath = to.location.pathname.split("/")
    const to_path = arrayPath[arrayPath.length - 1];
    if (authenticated()) {

        let permisos = getPermisos();
        permisos = permisos.map((element: any) => {
            return element.path.slice(1, element.path.length)
        })
        if (to_path === "home" || permisos.includes(to_path)) {
            next()
        } else {
            next.redirect("/home");
        }
    } else {
        next.redirect("/login");
    }
};