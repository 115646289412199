import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from "redux-thunk";
import loadings from './reducers/loadings';
import SearchBillsBar from './reducers/SearchBillsBar';
import sidebar from './reducers/sidebar';
import KeyReducer from './reducers/KeyReducer'
import Id from './reducers/Id';
import RegionalUploadBills from './reducers/RegionalUploadBills';
import PaginationBills from './reducers/PaginationBills';
import regionalSelect from './reducers/regionalSelect';
import paginationState from './reducers/pagination';
import dataLength from './reducers/dataLength';
import yearPicker from './reducers/yerarPicker';
import refreshTable from './reducers/refresh';
import modalUpload from './reducers/modalUpload';
import Drawer from "./reducers/drawer";
import statePayReducer from './reducers/statePayReducer';
import drawerPay from './reducers/drawerPay';
import datePickerState from "./reducers/datePickerRange";
import uploadPayModal from './reducers/uploadPayModal'
import payDatareucer from './reducers/payDatareucer'
import StatusSearchBar from './reducers/StatusSearchBar'
import CheckLastDate from './reducers/CheckLastDate'
import movii from './reducers/movii'
import pay from './reducers/pay'
import history from './reducers/history'
import popup from './reducers/popup'
import regionalGroup from './reducers/regionalGroup'
import updateRegionalModal from './reducers/updateRegionalModal'
import regionalGroupRecord from './reducers/regionalGroupRecord'
import regionalGroupCreate from './reducers/regionalGroupCreate'
import PopupStore from './reducers/PopupStore'
import reginalStateChanger from './reducers/reginalStateChanger'
import orderBy from './reducers/orderBy'
import PointDrawer from './reducers/PointDrawer'
import addButton from './reducers/addButton'
import editTime from './reducers/editTime'
import domains from './reducers/domains'
import domainsId from './reducers/domainsId'
import times from './reducers/times'
import paginationNumber from './reducers/paginationNumber'
import userModal from './reducers/userModal'
import passGen from './reducers/passGen'
import realPay from './reducers/realPay'
import userDrawer from './reducers/userDrawer'
import userAddReg from './reducers/userAddReg'
import parallelLoadin from './reducers/parallelLoadin'
import refetch from './reducers/refetch'
import createUserComp from './reducers/createUserComp'
import rolId from './reducers/rolId'
import sortedBills from './reducers/sortedBills'
import misscellaniousModal from './reducers/misscellaniousModal'
import missellaniousId from './reducers/missellaniousId'
import misscellaniousEditModal from './reducers/misscellaniousEditModal'
import changeUpload from './reducers/changeUpload'
import reportsModal from './reducers/reportsModal'
import moviiLoadings from './reducers/moviiLoadings'
import marketerState from './reducers/marketerState'
import moviiData from './reducers/moviiData'

const composeEnhancers =
  (typeof (window as any) !== "undefined" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const saveToLocalStorage = (state: any) => {
  const { yearPicker, regionalSelect } = state;
  const dataRedirect: any = { yearPicker, regionalSelect }
  try {
    const serializedState = JSON.stringify(dataRedirect)
    localStorage.setItem('state', serializedState)
  } catch (e) {
    console.error(e)
  }
}

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState)
  } catch (e) {
    console.error(e)
    return undefined;
  }
}

const reducer = combineReducers({
  loadings,
  SearchBillsBar,
  sidebar,
  KeyReducer,
  RegionalUploadBills,
  PaginationBills,
  yearPicker,
  Id,
  paginationState,
  dataLength,
  regionalSelect,
  refreshTable,
  Drawer,
  modalUpload,
  uploadPayModal,
  drawerPay,
  statePayReducer,
  datePickerState,
  payDatareucer,
  StatusSearchBar,
  CheckLastDate,
  movii,
  pay,
  history,
  popup,
  regionalGroup,
  updateRegionalModal,
  regionalGroupRecord,
  regionalGroupCreate,
  PopupStore,
  reginalStateChanger,
  orderBy,
  PointDrawer,
  addButton,
  editTime,
  domains,
  domainsId,
  times,
  userModal,
  passGen,
  realPay,
  userDrawer,
  userAddReg,
  parallelLoadin,
  refetch,
  createUserComp,
  rolId,
  sortedBills,
  misscellaniousModal,
  missellaniousId,
  misscellaniousEditModal,
  changeUpload,
  reportsModal,
  moviiLoadings,
  paginationNumber,
  marketerState,
  moviiData
});

const persistenState = loadFromLocalStorage();

const store = createStore(
  reducer,
  persistenState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;
