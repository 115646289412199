import { useEffect } from 'react'
import store from "../../store/store";
import { useSelector } from "react-redux";
import AppRoute from '../../router/appRouter';
import SideMenu from '../molecules/Sidebar';
import moment from 'moment'
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout, Image, Row, Col, Divider } from "antd";

const Home = () => {
  const { Content, Footer, Sider } = Layout;
  const state: any = useSelector((state) => state);
  const { switcher, themes } = useThemeSwitcher();
  useEffect(() => {
    switcher({ theme: themes.light });
  }, [switcher, themes.light])
  return (
    <Layout style={{
      height: "100%",
      width: "100%",
      minHeight: 985,
    }}>
      <Sider
        style={{ height: "auto", minHeight: 340 }}
        collapsible collapsed={state.sidebar}
        onCollapse={() => store.dispatch({ type: "setSidebarCollapse", payload: !state.sidebar })}
      >
        <Divider orientation="left"></Divider>
        <Row justify="space-around" align="middle">
          <Col>
            <Image
              preview={false}
              width={state.sidebar ? 50 : 80}
              style={{ cursor: "pointer" }}
              onClick={() => window.location.href = '/home'}
              src={process.env.REACT_APP_BACKEND_URL+`/downloads/avatars/emsa.jpg`}
            />
          </Col>
        </Row>
        <Divider orientation="left" />
        <Row>
          <SideMenu />
        </Row>
      </Sider>
      <Layout className="site-layout" style={{ height: "100%", width: "auto" }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 10,
            minHeight: 340,
            width: "auto"
          }}>
          <AppRoute />
        </Content>
        <Footer style={{ textAlign: 'center', minHeight: 200 }}>Vertebra soluciones ©{moment().format("YYYY")} Todos los derechos reservados </Footer>
      </Layout>
    </Layout>
  )
};
export default Home;