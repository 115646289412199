import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    isVisible: false,
    tags: [],
    record: {},
    month: 0
};
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setUploadModal":
            return payload
        default:
            return state;    
    }
}
export default reducer;