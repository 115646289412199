import ReactDOM from 'react-dom';
import store from './store/store';
import { Provider } from 'react-redux';
import MainRoute from "./router/mainRouter";
import { ApolloProvider } from "@apollo/client";
import "../node_modules/antd/dist/antd.compact.css";
import { createApolloClient } from "./plugins/graphql";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ConfigProvider } from 'antd';

import esES from 'antd/lib/locale-provider/es_ES'
const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};
const client = createApolloClient();
ReactDOM.render(
  <Provider store={store}>
    <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
      <ApolloProvider client={client}>
        <ConfigProvider locale={esES}>
          <MainRoute />
        </ConfigProvider>
      </ApolloProvider>
    </ThemeSwitcherProvider>
  </Provider>,
  document.getElementById('root')
)


