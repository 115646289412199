import swal from "sweetalert";
import { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useLoginMutation, SignIn } from "../generated/graphql";
import { setToken, getPayload, setUsername } from "../utils/auth";
import store from "../store/store";
const LoginAuth = (element: SignIn) => {
  const history = useHistory()
  const [login, { loading }] = useLoginMutation({
    variables: { signIn: element }
  })
  useEffect(() => {
    store.dispatch({ type: "setState", payload: loading })
  }, [loading])
  const request = async (values: any) => {
    if (values.user?.toUpperCase() === 'EMSA' && values.password === 'emsa2020') {
      setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNrdGMwZDh1dTM3NTBrbnd3NXFpYXd6aTUiLCJuaWNrbmFtZSI6IkVNU0EiLCJuYW1lcyI6IlBSVUVCQSBERSBFTVNBIiwiZW1haWwiOiJlbXNhQG1haWwuY29tIiwiY29tcGFueSI6ImNxc3I0MXUwMDAzam90Z2dvZGQ4cjBiIiwiY29tcGFueV9uYW1lIjoiREFWSVZJRU5EQSIsImxhc3RuYW1lcyI6IkVNU0EiLCJwZXJtaXNvcyI6eyJtb2R1bG9zIjpbeyJpZCI6ImRlc2NhcmdhcyIsImljb24iOiJkb3dubG9hZGluZyIsIm5hbWUiOiJIZXJyYW1pZW50YSBkZSBkZXNjYXJnYSBkZSByZXBvcnRlcyIsInBhdGgiOiIvZG93bmxvYWRzIiwidGl0bGUiOiJEZXNjYXJnYXMgZGUgcmVwb3J0ZXMiLCJhY3Rpb25zIjpbeyJ0eXBlIjoiU0VMRUNUIiwiY29uc3RyYWlucyI6W119LHsidHlwZSI6IklOU0VSVCIsImNvbnN0cmFpbnMiOltdfSx7InR5cGUiOiJERUxFVEUiLCJjb25zdHJhaW5zIjpbXX0seyJ0eXBlIjoiVVBEQVRFIiwiY29uc3RyYWlucyI6W119LHsidHlwZSI6IkRPV05MT0FEIiwiY29uc3RyYWlucyI6W119XSwic3VibWVudSI6Im1vZHVsbyIsImljb25fc2hvcnQiOiJEZSIsInNob3J0X25hbWUiOiJEZXNjYXJnYXMiLCJkZXNjcmlwdGlvbiI6IkhlcnJhbWllbnRhcyBkZXNjYXJnYXMgZGUgcmVwb3J0ZXMifSx7ImlkIjoidXBsb2FkZXIiLCJpY29uIjoidXBsb2FkIiwibmFtZSI6Ik3Ds2R1bG8gZGUgY2FyZ2EgZGUgaW5mb3JtYWNpw7NuIiwicGF0aCI6Ii91cGxvYWRlciIsInRpdGxlIjoiVXBsb2FkZXIiLCJhY3Rpb25zIjpbeyJ0eXBlIjoiU0VMRUNUIiwiY29uc3RyYWlucyI6W119LHsidHlwZSI6IklOU0VSVCIsImNvbnN0cmFpbnMiOltdfSx7InR5cGUiOiJERUxFVEUiLCJjb25zdHJhaW5zIjpbXX0seyJ0eXBlIjoiVVBEQVRFIiwiY29uc3RyYWlucyI6W119LHsidHlwZSI6IkNBTkNFTF9KT0IiLCJjb25zdHJhaW5zIjpbXX0seyJ0eXBlIjoiU0FWRV9KT0IiLCJjb25zdHJhaW5zIjpbXX0seyJ0eXBlIjoiUk9MTEJBQ0tfSk9CIiwiY29uc3RyYWlucyI6W119XSwic3VibWVudSI6Im1vZHVsbyIsImljb25fc2hvcnQiOiJVUCIsInNob3J0X25hbWUiOiJVcGxvYWRlciIsImRlc2NyaXB0aW9uIjoiTcOzZHVsbyBwYXJhIGNhcmdhIG1hc2l2YSBkZSBpbmZvcm1hY2nDs24ifV0sImlzU3VwZXJ2aXNvciI6dHJ1ZX0sInJvbCI6IkVNU0EiLCJhcHBsaWNhdGlvbl9pZCI6IjIiLCJjb21wYW5pZXNfYXNzaW5nIjp7ImNvbXBhbmllcyI6W3siaWQiOiJjcXNyNDF1MDAwM2pvdGdnb2RkOHIwYiIsInJlcG9ydHMiOlsiY3F6bG54YzBheGs0YnRnbmNwZ2dnOHgiLCJjcXpsbnhjMGF4bDRidGd5bWNsMG1yMyIsImNxemxueGMwYXhtNGJ0Z3AybzBrbjdjIl19XX0sImlhdCI6MTYzMjMyNzY0MywiZXhwIjoxNjMyNDE0MDQzfQ.Z4HwF2mrQhQF9hGEfxuL3yiOmRMR2G4NegBLWWlmCrw")
      setUsername(getPayload().nickname)
      history.push('/home')
    } else {
      swal({
        title: "Credenciales incorrectas",
        text: "contrasena o usuario incorrecto",
        icon: "error",
      });

    }
  }
  return request
};
export default LoginAuth;

