import { reduxType } from "../../interfaces/reducerType"
import moment from "moment";
const defaultState: any = {
    begin: moment().subtract(3, "M").days(1).minutes(0).hours(0),
    end: moment().days(1).minutes(0).hours(0)
};
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setDatePickerState":
            return payload
        default:
            return state;    
    }
}
export default reducer;