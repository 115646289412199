import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    id: "",
    voucher_number: "",
    date_paid: "",
    date_voucher_uploaded: "",
    payment_method_marketer: "",
    cash_source: "",
    movii: "",
    image_voucher:  "",
    status_bill: "PAGADA",   
};
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setPay":
            return payload
        default:
            return state;    
    }
}
export default reducer;