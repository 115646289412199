import { logout } from "../utils/auth";
import { getToken } from "../utils/auth";
import { onError } from '@apollo/client/link/error';
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, concat } from "@apollo/client";
export const createApolloClient = () => {
    const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_URL + '/graphql' });
    const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: 'Bearer ' + getToken() || null,
            }
        });
        return forward(operation);
    }) 
    const logoutLink = onError((networkError: any) => {
        if (networkError) {
            if (networkError.statusCode === 401) {
                logout();
            }
        }
    })
    return new ApolloClient({
        link: concat(authMiddleware, logoutLink.concat(httpLink)),
        cache: new InMemoryCache(),
    });
};