import { Carousel, Image } from 'antd';
import '../../styles/SingInStyle.css'

const CarouselAntd = () => {
    return (
        <Carousel effect="fade" autoplay>
            <div>
                <Image preview={false} src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Ilustracion 1-02.png`} />
            </div>
            <div>
                <Image preview={false} src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Ilustracion 2-03.png`} />
            </div>
            <div>
                <Image preview={false} src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Ilustracion 3-04.png`} />
            </div>
        </Carousel>
    )
}

export default CarouselAntd;
