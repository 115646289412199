import { Card, Col, Row, Space, Image } from 'antd';
import moment from "moment";
import { Tabs } from 'antd';

const { TabPane } = Tabs;
const { Meta } = Card;

const items2 = [
    { dummy: true, _id: 'VI0303', punto: "ALBORADA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR1002', punto: "ALTO DE LA LUNA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0603', punto: "APIAY - SURIMENA 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: false, _id: 'TZ0101', punto: "BAJO MELUA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'RE0101', punto: "BALCONES", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0301', punto: "CABUYARO URBANO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0403', punto: "DESMOTADORA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0405', punto: "CAMINO REAL", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0204', punto: "CASETABLA - CANTACLARO 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: false, _id: 'AR1103', punto: "CASIBARE", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AC0305', punto: "CASTILLA - SAN LORENZO 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AC0301', punto: "CASTILLA URBANO 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI1004', punto: "CATAMA - CAÑOS NEGROS 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0211', punto: "CAUDAL", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0801', punto: "CHAVITA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR1101', punto: "CHINATA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0305', punto: "CORALINA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VT0101', punto: "CIRCUITO CARIMAGUA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PN0101', punto: "PLANAS 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: false, _id: 'AC0105', punto: "PALOMAS", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AC0403', punto: "DINAMARCA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0502', punto: "EL CASTILLO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR1201', punto: "EL CRUCE", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AC0203', punto: "VEREDA EL DOCE 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0901', punto: "EL KIOSKO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0216', punto: "ESMERALDA -CUMARAL 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0306', punto: "ESPERANZA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0104', punto: "GRANADA - SAN JUAN DE ARAMA 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0106', punto: "BATALLON VARGAS", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0107', punto: "BIBLIOTECA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0108', punto: "LA HOLANDA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PA0101', punto: "GUACOYO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AC0202', punto: "GUAMAL 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0803', punto: "GUAYURIBA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI1003', punto: "KIRPAS", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0602', punto: "LA COOPERATIVA 34.5 KV - VISTA HERMOSA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0501', punto: "MEDELLIN DEL ARIARI 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'MS0102', punto: "RURAL MESETAS", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'MS0101', punto: "URBANA MESETAS 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI1001', punto: "MORICHAL", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'OA0101', punto: "OASIS 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI1102', punto: "PACHAQUIARO 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI1105', punto: "POMPEYA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0304', punto: "PORFIA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0208', punto: "PORVENIR", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0104', punto: "PTO LOPEZ - MANACACIAS 34.5KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'RE0102', punto: "PUENTE AMARILLO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0902', punto: "PUERTO LIMON 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0102', punto: "CANOERO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0103', punto: "LA BALSA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR1202', punto: "PUERTO RICO 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0202', punto: "REMOLINO", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0212', punto: "RURAL BARZAL", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0202', punto: "CAMOA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'AR0203', punto: "EL MEREY", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'SE0101', punto: "SERRANIA 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'TL0101', punto: "TILLAVA - PUERTO GAITAN", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'TI0101', punto: "TOLEDO 13.2KV", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0214', punto: "TRAPICHE", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'VI0103', punto: "TUNELES", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'UR0101', punto: "URIBE", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'TZ0102', punto: "VALDIVIA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'CU0104', punto: "VERACRUZ", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
    , { dummy: true, _id: 'PL0302', punto: "RURAL-VISO DE UPIA", status: 'Desconectado', falla: true, last_connection: 'Nunca' }
]

const items = [
    { dummy: true, _id: 'AC0102', punto: 'ACACIAS1 - SARDINATA', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0103', punto: 'ACACIAS2 - RETORNO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0104', punto: 'ACACIAS - GUAMAL 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0201', punto: 'GUAMAL', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0302', punto: 'CASTILLA 2 - ARENALES', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: false, _id: 'AC0303', punto: 'CASTILLA 3 - SAN LORENZO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0401', punto: 'SAN CARLOS DE GUAROA', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AC0402', punto: 'PALMERAS', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AL0102', punto: 'PUERTO GAITAN - MANACACIAS 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AL0103', punto: 'PUERTO TRIUNFO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0103', punto: 'GRANADA - SAN MARTIN  34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0301', punto: 'CUBARRAL URBANO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0302', punto: 'CUBARRAL RURAL', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0306', punto: 'CUBARRAL - ELDORADO - 34.5', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0401', punto: 'DORADO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0406', punto: 'ELDORADO - MEDELLIN -34.5', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0601', punto: 'VISTA HERMOSA', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0701', punto: 'SAN JUAN DE ARAMA 1', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0702', punto: 'SAN JUAN RURAL (MESETAS)', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0703', punto: 'SAN JUAN DE ARAMA - MESETAS 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0704', punto: 'SAN JUAN DE ARAMA - VISTAHERMOSA 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0801', punto: 'VEREDA EL DELIRIO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0802', punto: 'LEJANIAS', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR0903', punto: 'PUERTO LIMON - PUERTO LLERAS 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1001', punto: 'FUENTE DE ORO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1003', punto: 'FTE DE ORO - PTO LIMON 34.5', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1102', punto: 'PUERTO LLERAS', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1104', punto: 'PTO LLERAS - PTO RICO 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1301', punto: 'LA JULIA', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1302', punto: 'ORIENTE', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'AR1303', punto: 'DER. MESETAS - S/E MURIBA 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CR0101', punto: 'CRISTALINA 34.5KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CU0102', punto: 'RESTREPO', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CU0103', punto: 'CUMARAL', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CU0105', punto: 'SALINAS', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CU0201', punto: 'CUMARAL - JAPON - 34.5 KV', status: 'Normal', falla: false, last_connection: 'Nunca' },
    { dummy: true, _id: 'CU0202', punto: 'PRESENTADO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'JP0101', punto: 'JARDIN DE PEÑAS', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'MS0103', punto: 'MESETAS - URIBE 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PG0101', punto: 'NEBLINA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PG0102', punto: 'YUCAO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PG0103', punto: 'MANACACIAS - RUBI 34.5KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PL0201', punto: 'CASETABLA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PL0203', punto: 'CASETABLA - CABUYARO 34.5 kV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PL0205', punto: 'CASETABLA - EL RUBI 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PL0401', punto: 'BONGA 13.2 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'PL0403', punto: 'HUMAPO 13.2 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0104', punto: 'GALAN', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0105', punto: 'PIPIRAL', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0106', punto: 'REFORMA - ESMERALDA - 34.5', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0206', punto: 'BARZAL - IDEMA - 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0207', punto: 'ALMAVIVA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0209', punto: 'SAN ISIDRO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0213', punto: 'CENTRO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0215', punto: 'BARZAL - CAÑOS NEGROS 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0218', punto: 'PTO LOPEZ - CASETABLA - 34.5', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0222', punto: 'FUNDADORES - 34.5', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0307', punto: 'IDEMA 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0402', punto: 'CAÑOS NEGROS 1 - LA CEIBA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0404', punto: 'CAÑOS NEGROS 3 - RETIRO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0406', punto: 'CAÑOS NEGROS - CATAMA 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0501', punto: 'ESMERALDA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0601', punto: 'BARCELONA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0602', punto: 'LA CECILIA', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0802', punto: 'CUCHARON', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0804', punto: 'OCOA - ACACIAS 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI0806', punto: 'VILLAVICENCIO 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI1002', punto: 'GUAMO', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI1103', punto: 'SURIMENA 34.5 KV', status: 'Desconectado', falla: true, last_connection: 'Nunca' },
    { dummy: true, _id: 'VI1104', punto: 'LA LIBERTAD', status: 'Desconectado', falla: true, last_connection: 'Nunca' }

];

const emsameters = () => {
    const modulos = [1, 2, 3, 4, 5]
    return (
        <>

            <Tabs defaultActiveKey="1">
                <TabPane tab="Medidores No 1" key="1">
                    <Card size="small" title={<a href="/" >Panel de herramientas</a>} style={{ width: "auto", height: "auto" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {
                                items.map((element: any) => {
                                    const d1 = element.dummy ?
                                        moment()
                                            .format("YYYY-MM-DD")
                                        :
                                        moment()
                                            .set({ days: parseInt(moment().format("d")) - 2 })
                                            .format("YYYY-MM-DD")
                                    return (
                                        <Space key="dashboard">
                                            <Col className="gutter-row" span={6}>
                                                <Card style={{ color: "primary", width: 500 }} title={element.punto}>
                                                    <Meta
                                                        key="dashboard"
                                                        avatar={
                                                            <div>
                                                                <Image
                                                                    preview={false}
                                                                    style={{ width: "30px", padding: "0", margin: "0" }}
                                                                    src={element.dummy ? 'https://backend-unificado.vertebra-soluciones.com/downloads/avatars/green.png' : 'https://backend-unificado.vertebra-soluciones.com/downloads/avatars/red.png'}
                                                                />
                                                            </div>
                                                        }
                                                        title={"Serial: " + element._id}
                                                        description={" Ultima conexion: " + d1}
                                                    />
                                                </Card>
                                            </Col>
                                        </Space>
                                    )
                                })
                            }
                        </Row>
                    </Card>
                </TabPane>
                <TabPane tab="Medidores No 2" key="2">
                    <Card size="small" title={<a href="/" >Panel de herramientas</a>} style={{ width: "auto", height: "auto" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {
                                items2.map((element: any) => {
                                    const d1 = element.dummy ?
                                        moment()
                                            .format("YYYY-MM-DD")
                                        :
                                        moment()
                                            .set({ days: parseInt(moment().format("d")) - 2 })
                                            .format("YYYY-MM-DD")
                                    return (
                                        <Space key="dashboard">
                                            <Col className="gutter-row" span={6}>
                                                <Card style={{ color: "primary", width: 500 }} title={element.punto}>
                                                    <Meta
                                                        key="dashboard"
                                                        avatar={
                                                            <div>
                                                                <Image
                                                                    preview={false}
                                                                    style={{ width: "30px", padding: "0", margin: "0" }}
                                                                    src={element.dummy ? 'https://backend-unificado.vertebra-soluciones.com/downloads/avatars/green.png' : 'https://backend-unificado.vertebra-soluciones.com/downloads/avatars/red.png'}
                                                                />
                                                            </div>
                                                        }
                                                        title={"Serial: " + element._id}
                                                        description={" Ultima conexion: " + d1}
                                                    />
                                                </Card>
                                            </Col>
                                        </Space>
                                    )
                                })
                            }
                        </Row>
                    </Card>
                </TabPane>
            </Tabs>




        </>
    )
}
export default emsameters;