import { toPages } from './guards';
import loading from '../componetes/pages/loading';
import notFound from '../componetes/pages/notFound';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import emsadashasboard from '../componetes/templates/emsadashasboard';
import emsameters from '../componetes/templates/emsameters';
import emsameters2 from '../componetes/templates/emsameters2';
import dashboard from '../componetes/templates/dashboard';
const mainRouter = () => (
    <BrowserRouter>
        <GuardProvider loading={loading} error={notFound}>
            <Switch>
            <GuardedRoute path="/home" exact component={dashboard} />
                <GuardedRoute path="/home/emsadashboard" exact component={emsadashasboard} />
                <GuardedRoute path="/home/emsameters" exact component={emsameters} />
                <GuardedRoute path="/home/emsameters2" exact component={emsameters} />
            </Switch>
        </GuardProvider>
    </BrowserRouter>
);
export default mainRouter;
