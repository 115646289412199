import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {};
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setEditTime":
            return payload
        default:
            return state;    
    }
}
export default reducer;