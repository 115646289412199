import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    isVisible: false,
    record: [],
};
function reducer(state = defaultState, { type, payload }: reduxType) {
    switch (type) {
        case "setUserDrawer":
            return payload
        default:
            return state;
    }
}
export default reducer;