import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    popup: {
        record: [],
        visible: false,
        x: 0,
        y: 0
    }
};
function reducer(state = defaultState, { type, payload }: reduxType) {
    switch (type) {
        case "setPopup":
            return payload
        default:
            return state;
    }
}
export default reducer;