import { reduxType } from "../../interfaces/reducerType"
import moment from "moment";
const defaultState: number = parseInt(moment().format("YYYY"));
function reducer(state = defaultState, { type, payload }: reduxType) {
    switch (type) {
        case "setYearPicker":
            return payload
        default:
            return state;
    }
}
export default reducer;