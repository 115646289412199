import "../../styles/loginView.css";
import { Row, Layout, Col, Card } from "antd";
import LoginForm from "../organismos/loginForm";
import '../../styles/SingInStyle.css'
import CarouselAntd from "../molecules/Carousel";

const Login = () => {
  return (
    <Layout style={{ height: "100%", overflow: "hidden" }}>
      <div className="fondo">
        <div className="container">
          <div className="forms-containers">
            <div className="singIn">
              <Row justify="center" align="middle" gutter={[8, 8]}>
                <Card style={{ height: "auto", width: "50vw" }} className="card" cover={<span className="card-bacground"></span>}>
                  <Row gutter={24}>
                    <Col span={10}>
                      <LoginForm />
                    </Col>
                    <Col span={14}>
                      <CarouselAntd />
                    </Col>
                  </Row>
                </Card>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Login;
