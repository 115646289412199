import {
  UserOutlined,
  ControlOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { getPayload, getPermisos, logout, getTheme } from '../../utils/auth';

const { SubMenu } = Menu;

const MainTheme = getTheme()

function SiderMenu() {
  const payload = getPayload();
  const history = useHistory();
  const permisos = getPermisos();
  const state: any = useSelector((state) => state);
  return (
    <>
      {
        <Menu mode="inline" theme={MainTheme !== 'LIGHT' ? "dark" : "light"} style={{ height: "100%" }} >
          <SubMenu key="users" icon={<UserOutlined />} title={payload.names}>
            {state.sidebar ? <Menu.Item key="name" icon={<UserOutlined />} onClick={() => { history.push("/") }} >{payload.names}</Menu.Item> : <></>}
            <Menu.Item key="rol" icon={<ControlOutlined />} onClick={() => { history.push("/") }} >{payload.rol}</Menu.Item>
            <Menu.Item key="loguot" icon={<RollbackOutlined />} onClick={logout}>Cerrar Sesion</Menu.Item>
          </SubMenu>
            <Menu.Item style={{ height: "auto" }} key="3" onClick={() => { history.push('/') }} >Dashboard</Menu.Item>
            <Menu.Item style={{ height: "auto" }} key="1" onClick={() => { history.push('/home' + "/emsadashboard") }} >Fallas</Menu.Item>
            <Menu.Item style={{ height: "auto" }} key="2" onClick={() => { history.push('/home' + "/emsameters") }} >Medidores</Menu.Item>
        </Menu>
      }
    </>
  );
}
export default withRouter(SiderMenu);
