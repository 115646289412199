import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    isVisible: false,
    record: [],
    typeOf: "FACTURA",
    component: "",
};
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setDrawerPay":
            return payload
        default:
            return state;    
    }
}
export default reducer;