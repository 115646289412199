import jwtDecode from 'jwt-decode';
export const logout = function () {
    localStorage.removeItem('AUTH_TOKEN_KEY');
    localStorage.removeItem('AUTH_USER_KEY');
    localStorage.removeItem('state')
    setTheme("dark");
    window.location.reload();
}

export const setTheme = function (token: string) {
    localStorage.setItem('AUTH_THEME_KEY', token);
}

export const getTheme = function () {
    const theme = localStorage.getItem('AUTH_THEME_KEY');
    if (theme) {
        return theme
    } else {
        return 'dark'
    }
}

export const setToken = function (token: string) {
    localStorage.setItem('AUTH_TOKEN_KEY', token);
}

export const getToken = function () {
    return localStorage.getItem('AUTH_TOKEN_KEY');
}

export const setUsername = function (Username: string) {
    localStorage.setItem('AUTH_USER_KEY', Username);
}

export const getUsername = function () {
    return localStorage.getItem('AUTH_USER_KEY');
}

export const getPayload = function () {
    const token = getToken();
    if (token) {
        const decoded: any = jwtDecode(token);
        return decoded
    }
    else {
        logout();
        return undefined;
    }
}

export const authenticated = function () {
    const token = getToken();
    if (token) {
        const payload = getPayload();
        if (new Date() >= new Date(payload.exp * 1000)) {
            return false;
        }
        if (payload && payload.nickname === getUsername()) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export const organizarPermisos = function (modulos: any) {
    const permisos = {
        modulos: [] as any,
    }
    if (modulos && modulos.length) {
        for (let index = 0; index < modulos.length; index++) {
            const element = modulos[index];
            if (element.submenu === "modulo") {
                permisos.modulos.push(element)
            } else {
                continue
            }
        }
    }
    return permisos.modulos;
}

export const getActions = function (path: String) {
    const permisos = getPermisos().filter((element: any) => {
        return element.path === path;
    })
    if (permisos && permisos.length > 0) return permisos[0].actions
    return []
}

export const getPermisos = function () {
    const payload = getPayload();
    if (payload) {
        let permisos = payload.permisos
        return organizarPermisos(permisos.modulos);
    }
    else {
        logout();
        return false;
    }
}