import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    id: "",
    visible: true,
};
function reducer(state = defaultState, { type, payload }: reduxType) {
    switch (type) {
        case "setDomainsId":
            return payload
        default:
            return state;
    }
}
export default reducer;