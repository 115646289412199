import { reduxType } from "../../interfaces/reducerType"
const defaultState: any = {
    company_id: "",
    company_name: ""
};
function reducer(state = defaultState, { type, payload }: reduxType) {
    switch (type) {
        case "setCreateUserComp":
            return payload
        default:
            return state;
    }
}
export default reducer;