import { Card, Col, Row, Space } from 'antd';
import { useHistory } from "react-router-dom";
const { Meta } = Card;
const Dashboard = () => {
    const history = useHistory();
    return (
        <>
            <Card size="small" title={<a href="/" >Panel de herramientas</a>} style={{ width: "auto", height: "auto" }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Space size="small" key="dashboard" >
                        <Col className="gutter-row" span={6}>
                            <Card hoverable size="small" style={{ color: "primary", width: 400, marginTop: 10 }} title={"Tablero de ausencias de tesion"} onClick={() => history.push("/home/emsadashboard")}>
                                <Meta
                                    key="dashboard"
                                    avatar={<span className="material-icons" style={{ fontSize: 48 }}>{"dashboard"}</span>}
                                    title={"Tablero de ausencia de tension"}
                                    description={"Tablero de metricas de ausencia de tension"}
                                />
                            </Card>
                        </Col>
                    </Space>
                    <Space size="small" key="meters" >
                        <Col className="gutter-row" span={6}>
                            <Card hoverable size="small" style={{ color: "primary", width: 400, marginTop: 10 }} title={"Tablero de telemetria de medidores"} onClick={() => history.push("/home/emsameters")}>
                                <Meta
                                    key="meters"
                                    avatar={<span className="material-icons" style={{ fontSize: 48 }}>{"camera"}</span>}
                                    title={"Tablero de estado de medidores"}
                                    description={"Tablero de seguimiento de estado de medidores emsa"}
                                />
                            </Card>
                        </Col>
                    </Space>
                </Row>
            </Card>
        </>
    )
};

export default Dashboard;