import '../../styles/LogIn.css';
import { useState } from "react";
import { Input, Form, Button, Typography, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import requestLogin from "../../hooks/auth";
import { SignIn } from "../../generated/graphql";
import { Rules } from "../../utils/rulesValidation";
import '../../styles/SingInStyle.css'
import { useSelector } from 'react-redux'

const LoginForm = () => {
    const [user, setUser] = useState({ id: "2" } as SignIn);
    let response = requestLogin(user)
    const loading = useSelector((state: any) => state.loadings)
    const onFinish = (values: any) => {
        setUser({ ...user, user: values.username, password: values.password })
        return response({ ...user, user: values.username, password: values.password });
    };
    return (
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Image preview={false} style={{ width: "65%", marginLeft: "25%", marginBottom: -20, marginTop: -20 }} src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Smarteco-Verde-05.png`} />
            <Typography.Text style={{ color: "#444" }} className="title-form">Iniciar sesión</Typography.Text>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Porfavor ingresa un usuario valido!' }]}
            >
                <Input
                    className="input"
                    prefix={<UserOutlined />}
                    placeholder="Usuario" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Por favor ingresa una clave valida' }, { validator: Rules.min8character }]}
            >
                <Input.Password className="input"
                    prefix={<LockOutlined />}
                    placeholder="Contraseña"
                />
            </Form.Item>
            <Form.Item>
                <Button style={{ fontSize: "1rem", width: "80%", left: 35, borderRadius: 10 }} className="button" type="primary" htmlType="submit" loading={loading}>
                    INGRESAR
                </Button>
            </Form.Item>
            <Image preview={false} style={{ width: "50%", marginLeft: "25%", marginBottom: "10%" }} src={`${process.env.REACT_APP_BACKEND_URL}/downloads/avatars/Vt Verde-05.png`} />
        </Form>
    );
}
export default LoginForm;