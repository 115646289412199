//import { Layout } from 'antd';
import home from '../componetes/pages/home';
import login from '../componetes/pages/login';
import Spin from '../componetes/atoms/staticSpin';
import NotFound from '../componetes/pages/notFound';
import { toOutsideApp, toInsideApp } from './guards';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';

const MainRouter = () => (
  <BrowserRouter>
    <GuardProvider guards={[toInsideApp, toOutsideApp]} loading={Spin} error={Spin}>
      <Switch>
        <GuardedRoute path="/" exact component={login} />
        <GuardedRoute path="/login" exact component={login} />
        <GuardedRoute path="/home" component={home} />
        <GuardedRoute path="*" component={NotFound} />
      </Switch>
    </GuardProvider>
  </BrowserRouter>
);
export default MainRouter;
