import { reduxType } from "../../interfaces/reducerType";
const defaultState: any = "-1";
function reducer(state = defaultState, {type, payload }: reduxType) {
    switch (type) {
        case "setRegionalState":
            return payload
        default:
            return state;    
    }
}
export default reducer;